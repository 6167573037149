<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <div class="d-flex mb-9">
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img :src="defaultImage" class="shadow shadow-lg" alt="image" />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a href="#"
                  ><i class="ki ki-eye text-success font-size-h5 mr-3"></i
                ></a>
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >{{ observation_title }}</a
                >
              </div>

              <div class="my-lg-0 my-3">
                <a
                  v-if="hasPermission('Administrator, Editor')"
                  @click="editItem()"
                  class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3 cursor-pointer"
                  >UPDATE</a
                >
                <router-link to="/observations" v-slot="{ href, navigate }">
                  <a
                    :href="href"
                    @click="navigate"
                    class="btn btn-sm btn-primary font-weight-bolder text-uppercase mr-3"
                    >ALL OBSERVATIONS</a
                  >
                </router-link>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><i class="flaticon-avatar mr-2 font-size-lg"></i
                    >{{ user.firstname }} {{ user.lastname }}</a
                  >
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  >
                    <i class="flaticon-medal mr-2 font-size-lg"></i>
                    {{ user.role.name }}
                  </a>
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold"
                    ><i class="flaticon-placeholder-3 mr-2 font-size-lg"></i
                    >{{ location.name }}</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50">{{
                  description
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-solid"></div>
        <div class="d-flex align-items-center flex-wrap mt-8">
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon2-folder display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm"
                >Observation Category</span
              >
              <span class="text-primary font-weight-bolder font-size-h5">{{
                category.name
              }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon2-menu-4 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm"
                >Observation Type</span
              >
              <span class="text-primary font-weight-bolder font-size-h5">{{
                type.name
              }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-users-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Action Party</span>
              <span
                v-if="party_type == 'user'"
                class="text-primary font-weight-bolder font-size-h5"
                >{{ party.firstname }} {{ party.lastname }}</span
              >
              <span
                v-if="party_type == 'group'"
                class="text-primary font-weight-bolder font-size-h5"
                >{{ party.name }}</span
              >
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon2-hourglass-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column flex-lg-fill">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >Status</span
              >
              <a href="#" class="text-primary font-weight-bolder">{{
                status.name
              }}</a>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-event-calendar-symbol display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >Date</span
              >
              <a href="#" class="text-primary font-weight-bolder">{{
                timestamp
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <div class="card-body">
        <h3>Action Taken</h3>
        <hr />
        <p>{{ action }}</p>
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <div class="card-body">
        <h3>Recommendation</h3>
        <hr />
        <p>{{ recommendation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ObservationService from "@/core/services/observation.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "custom-page",
  components: {},
  computed: {
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"]),

    defaultImage() {
      if (this.evidences.length > 0) {
        return this.evidences[0].url;
      } else {
        return this.layoutConfig("placeholder");
      }
    }
  },
  data() {
    return {
      observation_title: "",
      description: "",
      action: "",
      recommendation: "",
      location: null,
      status: null,
      user: null,
      timestamp: "",
      category: null,
      type: null,
      party: null,
      party_type: null,
      locations: [],
      statuses: [],
      categories: [],
      types: [],
      parties: [],
      evidences: []
    };
  },
  mounted() {
    this.getObservation();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Observations", route: "observations" },
      { title: "Observation Details" }
    ]);
  },
  methods: {
    getObservation() {
      ObservationService.get(this.$route.params.id)
        .then(response => {
          console.log(response.data);
          this.observation_title = response.data.title;
          this.location = response.data.location;
          this.category = response.data.classification;
          this.type = response.data.observation_type;
          this.description = response.data.description;
          this.action = response.data.action_taken;
          this.recommendation = response.data.recommendation;
          this.status = response.data.status;
          this.timestamp = response.data.timestamp;
          this.user = response.data.user;
          if (response.data.action_party_users !== null) {
            this.party = response.data.action_party_users;
            this.party_type = "user";
          } else {
            this.party = response.data.action_party_groups;
            this.party_type = "group";
          }
          this.evidences = response.data.observation_evidences;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    editItem() {
      this.$router.push(`/observations/edit/${this.$route.params.id}`);
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
